.privacy-v1 {
	margin-top: 160px;

	.heading1 {
		word-break: break-word;
	}

	p {
		word-break: break-word;
	}
}