.footer-v1 {
	padding: 50px 0 100px 0;
	border-top: 1px solid #ccc;

	.footer-sec {
		background: var(--color-primary);
		padding: 50px 50px 70px 50px;
		border-top: 1px solid #fff;

		.footer-newsletter {
			max-width: 685px;
			width: 100%;
			margin: 0 auto;

			h3 {
				color: #171717;
				text-align: center;
			}

			p {
				font-size: 14px;
			}

			.input-group {
				width: 100%;

				.form-control {
					width: 100%;
					margin-bottom: 1rem;
					font-size: 13px;
					height: 35px;
				}
			}

			.input-group-append {
				margin-left: 0;

				.btn {
					color: #000 !important;
					background-color: #fff !important;
					border-color: #d9d9d9;
					border-radius: 0;
					padding: 9px 16px;
					font-weight: 400;
					font-size: 13px;
					text-decoration: none;
					line-height: normal;
					margin-bottom: 1rem;

					&:hover {
						background-color: #d9d9d9 !important;
						border-color: #b3b3b3;
					}
				}
			}
		}
	}

	.footer-last {
		margin-top: 120px;

		.footer-logo {
			margin-bottom: 30px;

			@media (max-width: 767px) {
				text-align: center;
			}

			img {
				max-width: 130px;
				width: 100%;
			}
		}

		p {
			color: #262c58;
			font-size: 14px;
			font-weight: 200;
			text-transform: uppercase;
			line-height: 1.67em;
			margin: 0;
		}

		h6 {
			color: #95874d;
			font-weight: normal;
			margin: 5px 0 15px;
			font-size: 16px;
		}

		.list-unstyled {
			display: flex;
			flex-wrap: wrap;

			li {
				margin-right: 10px;

				a {
					color: #121212;
					font-weight: normal;
					font-size: 16px;
					text-decoration: none !important;

				}
			}
		}

		.social-icons {
			margin: 0 !important;
			text-align: right;

			@media (max-width: 767px) {
				text-align: Center;
				margin-top: 50px !important;
			}

			li {
				a {
					color: #0d102a;
					font-size: 34px;

					&:hover {
						color: #ff7797;
					}
				}

			}
		}
	}


	.main-footer {

		background-color: var(--footer-bg-color);
		color: var(--footer-font-color);
		border-top: 1px solid var(--footer-border-top-color);

		.text-muted {
			color: var(--color-grey-normal) !important;

		}

		padding-left: 5vw;
		padding-right: 5vw;

		.headline {
			font-size: 20px;
			color: var(--color-white);
			text-transform: uppercase;
			font-family: var(--title-font-family-main);
		}


		.logo {
			width: 250px;
			height: auto;
			margin-top: -40px;
			padding-bottom: 20px;
		}

		.openings {

			div {
				padding-top: 10px;
			}
		}

		a {
			color: var(--footer-font-color);
		}

		.social-icons {

			li {

				a {
					color: var(--footer-font-color);
				}

				.fa {
					font-size: 20px;
					color: var(--footer-font-color);

				}
			}

		}




		#button-addon1 {
			color: #ffc371;
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa {
				color: var(--footer-icon-color);
			}
		}

		i {
			color: #ffc371;
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}
	}

	.copyright {
		background-color: var(--footer-copyright-bg-color);
		color: var(--footer-copyright-font-color);
	}

	.nl-answer {
		color: #fff !important;
		max-width: 685px;
		width: 100%;
		margin: 0 auto;
	}

}