.eigentuemer-page-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		background-image: url(RESOURCE/img/inner-banner.jpg);
		background-position: center bottom;
	}

	.eigentuemer-content-first {
		align-items: center;
		justify-content: center;
		margin-bottom: 40px;

		.first {
			p {
				margin: 0 0 5px 0;
			}

			h2 {
				font-size: 60px;

				@media (max-width: 767px) {
					font-size: 40px;
					margin: 0;
				}
			}
		}

		ul {
			list-style: disc;
			padding-left: 20px;

			li {
				margin-bottom: 10px;
			}
		}
	}
}