.facility-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.region-name {
		margin-top: -20px;
		color: var(--color-grey-dark);
	}

	.facility-map {
		width: 100%;
		height: var(--facility-map-height);
		position: relative;
	}

	.facilitylogo-161963 {
		background-image: url(RESOURCE/img/villa-fresena-logo.svg);
		width: 225px;
		height: 115px;
		margin: 0 auto 30px;
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: 225px;
	}

	.facilitylogo-162884 {
		background-image: url(RESOURCE/img/sandy-logo-1.svg);
		width: 225px;
		height: 90px;
		margin: 0 auto 30px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: 225px;
	}

	.facilitylogo-161965 {
		background-image: url(RESOURCE/img/sand-und-salz-logo-1.svg);
		width: 225px;
		height: 215px;
		margin: 0 auto 30px;
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: 225px;
	}

}