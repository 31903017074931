.unit-request-form-v1 {
	.bg-banner {
		background-image: url(RESOURCE/img/anfragen-bg.jpg);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.checkbox label {
		vertical-align: inherit;
	}

	.unit-pic {
		img {
			-o-object-fit: cover;
			object-fit: cover;
			-o-object-position: center center;
			object-position: center center;
			height: 100%;
			width: 100%;
			opacity: 0.5
		}
	}

	.asd__day.asd__day--enabled.asd__day--disabled {
		button {
			text-decoration: none;
		}
	}

	.asd__day--disabled.asd__day--in-range {
		background: var(--cal-selection-range) !important;
		opacity: 1 !important;
		border: 1px double var(--cal-selected-border) !important;

		button {
			text-decoration: underline !important;
		}
	}

	.asd__day--enabled.asd__day--in-range {
		background: #4fa2dc !important;
		color: var(--cal-text-color-selected) !important;
		opacity: 1 !important;
		border: 1px double var(--cal-selected-border) !important;

		button {
			text-decoration: none !important;
		}

	}


	.asd__day--disabled.asd__day--hovered {
		background: var(--cal-selection-range) !important;
		opacity: 1 !important;
		border: 1px double var(--cal-selected-border) !important;
	}

	.asd__day--enabled.asd__day--hovered {
		background: #4fa2dc !important;
		opacity: 1 !important;
		border: 1px double var(--cal-selected-border) !important;
	}

	.asd__day--arrival.asd__day--scope.asd__day--no-arrival {
		color: #959595 !important;

		button {
			text-decoration: underline !important;
		}

	}

	.asd__day--enabled {
		background-color: var(--cal-available) !important;
		color: var(--cal-text-color) !important;
		font-weight: 400;


		button {
			text-decoration: none;
		}

		&.asd__day--disabled {
			background-color: var(--cal-disabled) !important;
			opacity: 0.8 !important;
			text-decoration: underline;
			color: #959595 !important;

			&.asd__day--selected {
				background-color: #0770ba !important;
				color: #fff !important;
			}

			&.asd__day--in-range {
				background: var(--cal-selection-range) !important;
				opacity: 1 !important;
				border: 1px double var(--cal-selected-border) !important;
			}

			/*	&.asd__day--hovered {
				background: var(--cal-selection-range) !important;
				opacity: 1 !important;
				border: 1px double var(--cal-selected-border) !important;
			} */
		}


	}

	.asd__day--enabled:not(.asd__day--disabled) {
		&:hover {
			background: #0770ba !important;
			opacity: 1 !important;
			color: var(--cal-text-color-hover) !important;
		}
	}

	.asd__day--selected {
		background: #0770ba !important;
		color: var(--cal-text-color-selected) !important;
		opacity: 1 !important;
		border: 1px double var(--cal-selected-border) !important;

		&.asd__selected-date-one {
			position: relative;

			&:before {
				content: "";
				position: absolute;
				border-left: 12px solid transparent;
				border-top: 19px solid #4fa2dc;
				border-bottom: 20px solid #4fa2dc;
				right: 0px;
			}
		}

		&.asd__selected-date-two {
			position: relative;

			&:before {
				content: "";
				position: absolute;
				border-right: 12px solid transparent;
				border-top: 19px solid #4fa2dc;
				border-bottom: 20px solid #4fa2dc;
				left: 0px;
			}
		}

	}

}