.search-page-layout-v3 {
	background-color: var(--color-white);
	margin-top: var(--page-margin-top);

	.item {
		transition: all .2s;
		transform: scale(1);
		display: block;


		p {
			color: var(--font-color-main);
		}

		text-decoration:none;

		&:hover {
			transform: scale(1.015);
			border: 1px solid var(--color-secondary);

		}
	}

	.bdr-left-bl {
		border-left: 10px solid var(--btn-primary-bg-color);
		background-color: #f2f2f0;
		padding: 20px 30px;
		margin-bottom: 20px;
	}

	.results {
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 30px;

		@media (min-width:1200px) {
			grid-template-columns: 1fr 1fr;
			column-gap: 30px;
		}

		/*	@media (max-width:992px) {
			grid-template-columns: 1fr 1fr;
			column-gap: 30px;
		} */

		@media (max-width:992px) {
			grid-template-columns: 1fr;
		}

	}

	.more-btn-panel {
		margin-top: 30px;
	}

	.container,
	.container-xl {
		@media (min-width:1200px) {
			/*max-width: 1370px; */
		}

		@media (min-width:1500px) {
			max-width: 1370px;
		}
	}

}

.my-search-token-v2 {
	margin: 2px;
	padding: 2px 6px;
	display: inline-block;
	border: 1px solid var(--color-grey-normal);
	border-radius: 4px;
	background-color: var(--color-grey-light);
	font-size: 14px;
	cursor: pointer;
	position: relative;
	transition: all 0.5s ease;
	margin-right: 8px;

	.close {
		transition: all 0.5s ease;
		position: absolute;
		height: 13px;
		width: 13px;
		right: -7px;
		top: -7px;
		font-size: 14px;
		text-align: center;
		background-color: var(--color-primary);
		color: #fff;
		border-radius: 50%;
		font-weight: 400;
		line-height: 1px;
		padding-bottom: 2px;
		opacity: 0;
	}

	&:hover {
		border: 1px solid var(--color-primary);
		background-color: var(--color-white);

		.close {
			opacity: 1;
		}
	}
}

.section-title {
	padding: 20px 30px;
	background-color: #ADA68D;
	margin-bottom: 20px;
}

.section-title h2 {
	font-size: 22px;
	font-weight: 700;
	line-height: 28px;
	color: #EEF0F1;
}

.img-responsive {
	max-width: 100%
}

.feature-box {
	color: #383E42;
	height: 100%;
	padding: 15px 30px;

	.fa-check-circle {
		font-size: 35px;
		color: #383E42;
		margin-bottom: 20px;
	}

	h3 {
		font-size: 18px;
		line-height: 21px;
		color: #383E42;

	}

}

.img-title {
	padding: 5px 10px;
	background-color: #ADA68D;
	margin-bottom: 20px;
	text-align: center;

	h3 {
		font-size: 18px;
		font-weight: 600;
		line-height: 23px;
		color: #EEF0F1;
	}
}

.feature-bl {
	.col-md-3 {
		@media(max-width:992px) {
			margin-bottom: 20px;
		}
	}
}

.text {
	@media(min-width:992px) {
		margin-left: 60px;
	}
}

.list-style {
	list-style: decimal;
	padding-left: 20px;
	position: relative;

	li {
		margin-bottom: 10px;
	}
}