.contact-form-v1 {
	padding-bottom: 62px !important;

	.checkbox label {
		vertical-align: inherit;
	}

	.red {
		color: var(--color-red);
	}

	.checkbox label:before {
		top: 5px;
	}

	.checkbox label:after {
		top: 4px;
	}

	.footer-link {
		cursor: pointer;
	}
}