.unit-feedbacks-v1 {

	.widget-header {
		display: flex;
		align-items: Center;
		flex-wrap: wrap;

		.rating {
			font-size: 20px;
			color: var(--color-primary);
			margin: 0 0 0 15px;
		}
	}

	.widget-block {
		display: flex;
		flex-wrap: wrap;

		.rating {
			display: flex;
			width: 50%;
			margin: 0 0 10px 0;

			@media (max-width: 991px) {
				width: 100%;
				flex-wrap: wrap;
			}

			.ptitle {
				margin: 0 10px 0 0;
			}

			.stars {
				color: var(--color-primary);
				max-width: 115px;
				width: 100%;

				@media (max-width: 991px) {
					width: 100%;

				}
			}
		}
	}

	.ptitle {
		display: inline-block;
	}

	.ratings {
		display: flex;
		flex-wrap: wrap;

		.rating {
			margin: 0 30px 15px 0;

			.fa {
				color: var(--color-primary)
			}
		}
	}

	.feedbacks {

		clear: left;
		padding-top: 15px;

		.feedback {
			border-left: 3px solid var(--color-grey-normal);
			background-color: var(--color-grey-light);
			padding: 10px;
			font-style: italic;
			margin-bottom: 30px;

			.rating {
				font-size: var(--font-size-sm);
			}

			.guest-text {
				clear: left;
				padding-top: 10px;
				font-size: var(--font-size-md);
			}

			.author {
				font-size: var(--font-size-sm);
			}

			.comment {
				padding-left: 10px;
				border-left: 3px dotted var(--color-red);
				font-size: var(--font-size-md);
			}

			.agency {
				padding-bottom: 10px;
			}
		}
	}
}