.unit-page-layout-v1 {
	margin-top: var(--page-margin-top);

	@media (max-width: 767px) {
		margin-top: 160px;
	}

	@media all and (min-width: 768px) and (max-width: 992px) {
		margin-top: 170px;
	}

	.left-col {
		padding: 0px 15px;


		@media(max-width: 1200px) {
			width: 100% !important;
		}


		@media(min-width: 1201px) {
			width: 68% !important;
		}


		@media(min-width: 1500px) {
			width: 70% !important;
		}
	}


	.right-col {

		padding: 0px 15px;


		@media(max-width: 1200px) {
			display: none;
		}


		@media(min-width: 1201px) {
			width: 32% !important;
		}


		@media(min-width: 1500px) {
			width: 30% !important;
		}
	}

	.booking-hint {
		font-size: var(--font-size-md);
		margin-top: -20px;
		padding-bottom: 10px;
	}

	.unit-book-now-v3 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			display: none;
			margin-bottom: 57px;
		}


		&.is-visible {
			@media(min-width:992px) {
				display: block;
			}
		}
	}

	.unit-book-now-v4 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			margin-bottom: 57px;
		}


	}

	.unit-banner-img-1 {
		width: 100%;
		margin-top: 50px;
		margin-bottom: 40px;
		display: block;

		img {
			width: 100%;
			height: 500px;
			object-fit: cover;

			@media (max-width: 767px) {
				height: 350px;
			}

			@media (max-width: 580px) {
				height: 300px;
			}
		}
	}

	.Virtuelle-Tour {
		.widget-block {
			padding-bottom: 8px;
		}

		iframe {
			width: 100%;
			height: 670px;
			border: none;

			@media (max-width: 1199px) {
				height: 500px;
			}

			@media (max-width: 992px) {
				height: 500px;
			}

			@media (max-width: 767px) {
				height: 420px;
			}
		}
	}

	.video-container-hoch {
		/*position: relative;*/
		text-align: center;

		.iframe-hoch {
			/*position: absolute;
			top: 0;
			left: 0;*/
			width: auto;
			height: 534px;
			border: 0;



		}

	}

	.video-container-quer {
		/*position: relative;*/

		iframe {
			/*position: absolute;
			top: 0;
			left: 0;*/
			width: 100%;
			height: 100%;
			border: 0;
			aspect-ratio: 16/9;
		}
	}


	.unit-contact-form-v1 {
		.asd__day.asd__day--enabled.asd__day--disabled {
			button {
				text-decoration: none;
			}
		}

		.asd__day--disabled.asd__day--in-range {
			background: var(--cal-selection-range) !important;
			opacity: 1 !important;
			border: 1px double var(--cal-selected-border) !important;

			button {
				text-decoration: underline !important;
			}
		}

		.asd__day--enabled.asd__day--in-range {
			background: #4fa2dc !important;
			color: var(--cal-text-color-selected) !important;
			opacity: 1 !important;
			border: 1px double var(--cal-selected-border) !important;

			button {
				text-decoration: none !important;
			}

		}


		.asd__day--disabled.asd__day--hovered {
			background: var(--cal-selection-range) !important;
			opacity: 1 !important;
			border: 1px double var(--cal-selected-border) !important;
		}

		.asd__day--enabled.asd__day--hovered {
			background: #4fa2dc !important;
			opacity: 1 !important;
			border: 1px double var(--cal-selected-border) !important;
		}

		.asd__day--arrival.asd__day--scope.asd__day--no-arrival {
			color: #959595 !important;

			button {
				text-decoration: underline !important;
			}

		}

		.asd__day--enabled {
			background-color: var(--cal-available) !important;
			color: var(--cal-text-color) !important;
			font-weight: 400;


			button {
				text-decoration: none;
			}

			&.asd__day--disabled {
				background-color: var(--cal-disabled) !important;
				opacity: 0.8 !important;
				text-decoration: underline;
				color: #959595 !important;

				&.asd__day--selected {
					background-color: #0770ba !important;
					color: #fff !important;
				}

				&.asd__day--in-range {
					background: var(--cal-selection-range) !important;
					opacity: 1 !important;
					border: 1px double var(--cal-selected-border) !important;
				}

				/*	&.asd__day--hovered {
				background: var(--cal-selection-range) !important;
				opacity: 1 !important;
				border: 1px double var(--cal-selected-border) !important;
			} */
			}


		}

		.asd__day--enabled:not(.asd__day--disabled) {
			&:hover {
				background: #0770ba !important;
				opacity: 1 !important;
				color: var(--cal-text-color-hover) !important;
			}
		}

		.asd__day--selected {
			background: #0770ba !important;
			color: var(--cal-text-color-selected) !important;
			opacity: 1 !important;
			border: 1px double var(--cal-selected-border) !important;

			&.asd__selected-date-one {
				position: relative;

				&:before {
					content: "";
					position: absolute;
					border-left: 12px solid transparent;
					border-top: 19px solid #4fa2dc;
					border-bottom: 20px solid #4fa2dc;
					right: 0px;
				}
			}

			&.asd__selected-date-two {
				position: relative;

				&:before {
					content: "";
					position: absolute;
					border-right: 12px solid transparent;
					border-top: 19px solid #4fa2dc;
					border-bottom: 20px solid #4fa2dc;
					left: 0px;
				}
			}

		}
	}
}